import useLadyService from "@/services/LadyService";
import { Button, Icon } from "@/components/ui";

import { Link } from "react-router-dom";
import AttentionBox from "../components/AttentionBox/AttentionBox";
import GuideTags from "../components/tags/GuideTags";
import React, { useEffect, useState, useRef } from "react";

import shortFilter from "@/assets/img/guide/default-and-customer/short-filter.webp";
import filterImg from "@/assets/img/guide/default-and-customer/filter.webp";
import mapImg from "@/assets/img/guide/default-and-customer/map.webp";
import navImg from "@/assets/img/guide/default-and-customer/nav.webp";
import noteImg from "@/assets/img/guide/default-and-customer/note.webp";
import complainImg from "@/assets/img/guide/default-and-customer/complain.webp";
import tariffsImg from "@/assets/img/guide/default-and-customer/tariffs.webp";
import castingImg from "@/assets/img/guide/default-and-customer/photo_2024-06-16_17-45-44.jpg";
import deleteImg from "@/assets/img/guide/default-and-customer/delete-profile.webp";
import GuideAside from "../components/aside/GuideAside";
import {HashLink} from "react-router-hash-link";
import TariffsTable from "../../../tariffsTable/TariffsTable";
import {Helmet} from "react-helmet";

const GuideCustomer = ({ scrolledClass, show, setShow, setShowSearch, showSearch }) => {
  const { lang, city, t, windowWidth } = useLadyService();

  let checkCity = city ? city : 'prague'

  const IconTemplate = (translateKey, icon) => (
    <Icon
      title={t(translateKey)}
      square
      clazz={"color-green"}
      spritePath={icon}
      size={"l"}
    />
  );

  const [searchTerm, setSearchTerm] = useState('');
      const textRef = useRef(null);
      const [originalContent, setOriginalContent] = useState('');
    
      useEffect(() => {
        if (textRef.current) {
          setOriginalContent(textRef.current.innerHTML);
        }
      }, [textRef]);
    
      useEffect(() => {
        if (textRef.current) {
          textRef.current.innerHTML = originalContent;
      
          if (searchTerm !== '') {
            const regex = new RegExp(searchTerm, 'gi');
      
            function replaceTextInNodes(element) {
              if (!element || element.classList.contains('skip-search')) return;
      
              Array.from(element.childNodes).forEach(node => {
                console.log('node -', node)
                if (node.nodeType === Node.TEXT_NODE) {
                  const newText = node.textContent.replace(regex, (match) => `<mark>${match}</mark>`);
      
                  const tempElement = document.createElement('template');
                  tempElement.innerHTML = newText;
      
                  
                  Array.from(tempElement.content.childNodes).forEach(newNode => {
                    node.parentNode.insertBefore(newNode, node);
                  });

                  node.remove();
                } else if (node.nodeType === Node.ELEMENT_NODE) {
                  replaceTextInNodes(node);
                }
              });
            }
      
            replaceTextInNodes(textRef.current);
          }
          else{
            textRef.current.innerHTML = originalContent;
          }
        }
      }, [searchTerm, originalContent]);

      const [count, setCount] = useState(0)
      
    
      const handleScrollToSearch = () =>{
        const matches = textRef.current.querySelectorAll('mark');
        if (matches){
          if(count < matches.length){
            const target = matches[count];
            target.scrollIntoView({ behavior: 'smooth'});
            setCount((prev)=>prev+ 1)
          }
          else{
            setCount(0)
          }
          
        }
  
      }

  return (
    <main className="guide-page">
      <Helmet>
        <meta
            name="description"
            content={t("guidedesc")}
        />
      </Helmet>
      <section className="guide__container">
        <h1 className={"mb-24 mb-16-mob"}>{t("c-titleh1")}</h1>

        <Button
          clazz={`button--tetriary guide__button-heading${scrolledClass}`}
          onClick={() => setShow((prev) => !prev)}
        >
          <Icon size={"l"} spritePath={"instruction-cont"}/>
          {t("i-contents")}
        </Button>

        <Button
          size={"xs"}
          clazz={`search__icon guide__button-heading${scrolledClass}`}
          onClick={()=>{setShowSearch((prev) => !prev)}}
        >
            <Icon size={"m"} spritePath={"search"} />
        </Button>

        <p>
          {t("g-greetings")}{" "}
          <Link to={"/"} className={"green"}>
            Lady<span className={'color-green'}>4</span>Love
          </Link>{" "}
          {t("g-greetingstext")}
        </p>

        <div className="guide__body" >
          <GuideAside
            props={{
              show,
              setShow,
              scrolledClass,
              handleScrollToSearch,
              searchTerm,
              setSearchTerm,
              setShowSearch, 
              showSearch
            }}
          />

          <div className="guide__content" ref={textRef}>
            <h2>{t("g-portaltext")} Lady4Love</h2>

            <h3 id={"guarantee"}>{t("g-guarantee")}</h3>

            <p>{t("g-serviceacc")}.</p>

            <p>{t("g-securedata")}!</p>

            <AttentionBox type={"important"}>
              {t("i-phonetext")} <Link to={"/lk/support"} target={"_blank"}>{t("i-phonesupport")}</Link>{" "}
              {t("i-phonechange")}.
            </AttentionBox>

            <p>
              {t("onthe")} {t("your")} <b>Email</b> {t("and")}{" "}
              <b>WhatsApp/Telegram</b> {t("c-notify")}{" "}
              <Link to={`/${lang}/lk/customer/subscriptions`}
                    target={"_blank"}>{t("yoursubs")}</Link>, {t("andofferto")}{" "}
              <a href="#">{t("privateparty")}</a>.
            </p>

            <AttentionBox type={"advice"}>{t("g-confidenc")}.</AttentionBox>

            <h3 id={"sortAndFilter"}>{t("g-sortandfilter")}</h3>

            <div className={"guide__box--friend-agency"}>
              <img
                className={"float-left"}
                src={shortFilter}
                alt=""
              />

              <p>{t("g-filters")}.</p>

              <p>
                {t("g-sotrstatus")}{" "}
                <Link
                  className={"color-green"}
                  target={"_blank"}
                  to={`/prague/indi-elitelady-vchat`}
                >
                  {`${t("g-sotrstatuses")}`}.
                </Link>
              </p>
            </div>

            <h3 id={"saveFilters"}>{t("g-savefilters")}</h3>

            <div className={"guide__box--friend-agency"}>
              <img
                className={"float-right"}
                src={filterImg}
                alt=""
              />

              <p>
                {t("g-savecriterii")} <Link target={"_blank"}
                                            to={`/${lang}/lk/customer/subscriptions`}>{t("subandfilters")}</Link>
                .
              </p>

              <p>{t("g-subscribenotif")}.</p>

              <p>{t("g-privatpatry")}.</p>
            </div>

            <h3 id={"statusElite"}>{t("g-searchmodels")}</h3>

            <p>{t("g-searchmap")}.</p>

            <p>
              {t("g-nearescort")} <Link to={`/${lang}/lk/customer`}>{t("profile")}</Link>{" "}
              {t("g-searchradius")}.
            </p>
            <img className={"img-contain"} src={mapImg} alt=""/>

            <h3 id={"interactions"}>{t("g-interactions")}</h3>

            <AttentionBox type={"warning"}>
              {t("g-addfav")} <Link to={`/${lang}/history`}>{t("history")}</Link>{" "}
              {t("g-addfavaccess")}.
            </AttentionBox>

            <img src={navImg} className={"img-contain"} alt=""/>

            <h3 id={"notes"}>{t("g-modelsnotes")}</h3>

            <div className={"guide__box--friend-agency"}>
              <p>{t("g-uniquefeature")}.</p>

              <p>{t("g-serves")}.</p>

              <img
                className={"img-contain"}
                src={noteImg}
                alt=""
              />
            </div>

            <AttentionBox type={"advice"}>
              {t("g-quicklyfind")} <Link to={`/${lang}/history`}>{t("history")}</Link>{" "}
              {t("g-filtered")} «{t("i-notes")}».
            </AttentionBox>

            <h3 id={"feedback"}>{t("g-feedback")}</h3>
            <p>{t("g-commentirate")}.</p>
            <p>{t("g-reviews")}.</p>
            <p>
              {t("g-reviewsrate")} <Link to={`/${lang}/history`}>{t("history")}</Link>{" "}
              {t("g-filtered")} «{t("reviews")}».
            </p>

            <AttentionBox type={"important"}>{t("g-blocking")}</AttentionBox>

            <h3 id={"complaints"}>{t("g-complaints")}</h3>
            <div className={"guide__box--friend-agency"}>
              <img
                className={"float-right"}
                src={complainImg}
                alt=""
              />
              <p>
                {t("g-violations")}{' '}{t("g-sendcomplaint")}
                . {t("g-describe")}.
              </p>
              <p>{t("g-measures")}.</p>
              <p>
                {t("g-complaininsection")} <Link to={`/${lang}/history`}>{t("history")}</Link>{" "}
                {t("g-filtered")} «{t("i-complaint")}».
              </p>
            </div>

            {/*////////////////////////////////////////////////////////////////////*/}

            <h2>{t("g-uniquefeatures")}</h2>

            <h3 id="eroCoins">
              {IconTemplate("EroCoins", "erocoin")}
              EroCoins {t("and")} {t("i-payonportal")}
            </h3>

            <p>
              {t("i-cointext1")} <b>EroCoin</b>. {t("i-cointext2")}.
            </p>
            <p>{t("i-coinconvert")}.</p>

            <p>{t("i-coinsell")}.</p>

            <p>{t("i-coindeposit")}:</p>

            <ul>
              <li>{t("i-banktransit")} (IBAN).</li>
              <li>{t("i-bankcard")}.</li>
              <li>PayPal</li>
              <li>{t("i-crypto")}.</li>
            </ul>

            <h3 id={"vidWithModel"}>
              {IconTemplate("videochat", "video")}
              {t("videochatswithmodel")}
            </h3>

            <AttentionBox type={"warning"}>
              {t("g-vchatfeature")} <HashLink to={"#tariffs"}>Platinum</HashLink>{" "}
              {t("g-vhatpermonth")}.
            </AttentionBox>

            <p className="guide__box d-block">
              <span className={"d-block"}>{t("procedure")}:</span>
              {t("c-activefunction")} «{t("videochat")}» {t("c-havebutton")}{" "}
              <Button clazz={"button--green"} size={"xs"}>
                <Icon size={"m"} spritePath={"video"}/>
                {t("videochat")}
              </Button>{" "}
              ({t("g-fastsearch")}{" "}
              <Link className={"green"} target={"_blank"} to={`/${lang}/${checkCity}/vchat`}>
                {t("videochat")}
              </Link>
              ).
              <br/>
              <br/>
              {t("c-vchataction")}. <b>{t("c-vchatpay")}.</b>
            </p>

            <p>
              {t("c-vchatstore")}{" "}
              <Link to={`/${lang}/history`}>{t("history")}</Link>{" "}
              {t("g-filtered")} «{t("videochat")}».
            </p>

            <AttentionBox type={"advice"}>{t("c-vchatadvice")}.</AttentionBox>

            <h3 id={"eroContent"}>
              {IconTemplate("erocontent", "pants")}
              {t("erocontent")}
            </h3>

            <AttentionBox type={"warning"}>
              {t("g-eromodels")}.
              <br/>
              <br/>
              <b>{t("erocontent")}</b> {t("g-haveicon")}{" "}
              <Icon
                size={"l"}
                square
                clazz={"color-green"}
                spritePath={"pants"}
              />{" "}
              ({t("g-fastsearch")}{' '}
              <Link className={"green"} target={"_blank"} to={`/${lang}/${checkCity}/ero`}>
                {t("erocontent")}
              </Link>).
              <br/>
              <br/>
              {t("g-erocost")} <HashLink to={"#tariffs"}>Platinum</HashLink>{" "}
              {t("g-erocostrest")}.
              <br/>
              <br/>
              {t("g-erobuy")} <Link to={`/${lang}/history`}>{t("history")}</Link>{" "}
              {t("g-filtered")} «{t("erocontent")}».
            </AttentionBox>

            <AttentionBox type={"advice"}>{t("c-eroadvice")}.</AttentionBox>

            <h3 id={"preorders"}>
              {IconTemplate("onlineorder", "pay-content")}
              {t("g-preorders")} – {t("i-uniquefunc")}!
            </h3>
            <AttentionBox type={"warning"}>{t("g-pordermodel")}.</AttentionBox>

            <p className="guide__box d-block">
              <span className={"d-block"}>{t("procedure")}:</span>
              {t("c-activefunction")} «{t("onlineorder")}» {t("c-havebutton")}{" "}
              <Button
                title={t("order")}
                size={"xs"}
                clazz={"button_outline--green"}
              >
                <Icon spritePath={"pay-content"} size={"l"}/>
                {t("order")}
              </Button>{" "}
              ({t("g-fastsearch")}{" "}
              <Link
                className={"green"}
                target={"_blank"}
                to={`/${lang}/${checkCity}/booking`}
              >
                {t("onlineorder")}
              </Link>
              ).
              <br/>
              <br/>
              {t("c-order")}. <b>{t("c-vchatpay")}.</b> {t("c-orderdesc")}.
              <br/>
              <br/>
              {t("c-orderbefore")}.
            </p>

            <AttentionBox type={"important"}>
              {t("g-porderwarning")}.
            </AttentionBox>

            <p>
              {t("c-ordersave")} <Link to={`/${lang}/history`}>{t("history")}</Link>{" "}
              {t("g-filtered")} «{t("myorder")}».
            </p>

            <AttentionBox type={"advice"}>{t("c-vchatadvice")}.</AttentionBox>

            <h3 id={"previews"}>
              {IconTemplate("modelpreviews", "casting")}
              {t("modelpreviews")}
            </h3>

            <AttentionBox type={"warning"}>
              {t("g-pordercouple")}.
              <br/>
              {t("g-makechoise")}
            </AttentionBox>

            <p className="guide__box d-block">
              <span className={"d-block"}>{t("procedure")}:</span>
              {t("c-agencyserv")} «{t("c-ordercasting")}»
              <br/>
              <br/>
              {t("c-orderprocess")}. <b>{t("c-agencyorder")}.</b>{" "}
              {t("c-moneyagency")}.
              <br/>
              <br/>
              {t("c-moneybefore")}.
              <br/>
              {t("c-castingsave")} <Link to={`/${lang}/history`}>{t("history")}</Link>{" "}
              {t("g-filtered")} «{t("preview")}».
            </p>

            <img className={"img-contain"} src={castingImg} alt=""/>

            <AttentionBox type={"advice"}>{t("g-reviewcan")}.</AttentionBox>

            <h3 id={"parties"}>{t("g-party")}</h3>

            <AttentionBox type={"warning"}>
              {t("g-partyorg")} <HashLink to={"#tariffs"}>Platinum</HashLink>.
              <br/>
              {t("g-partysubscr")} <Link target={"_blank"}
                                         to={`/${lang}/lk/customer/subscriptions`}>{t("subandfilters")}</Link>. {t("g-incognitotext")}.
            </AttentionBox>

            <h2 id={"modelStatuses"}>{t("g-modelstatuses")}</h2>
            <GuideTags/>

            <h2>{t("lk")}</h2>

            <p id={"numbuster"}>
              <b>{t("checknum")}</b> - {t("checknumtext")}{" "}
              <Link
                to={"//numbuster.com/"}
                target={"_blank"}
                className={"green"}
              >
                Numbuster.
              </Link>{" "}
              {t("i-checknumres")}.
            </p>

            <p>{t("numverify")}.</p>

            <p>{t("mynumcheck")}.</p>

            <p id={"deleteProfile"}>
              <b>{t("deactdelete")}</b> - {t("deactivateprof")}:
            </p>

            <ul>
              <li>
                {t("subscrpay")} <b>{t("willnot")}</b> {t("subscrmonth")}.
              </li>
              <li>
                {t("c-savefilters")} {t("you")} <b>{t("willnot")}</b>{" "}
                {t("c-getinvates")}.
              </li>
            </ul>

            <p>
              {t("youcantry")} <b>{t("deleteprofile")}</b> {t("c-buttondown")}.
            </p>

            <img className={"img-contain"} src={deleteImg} alt=""/>

            <AttentionBox type={"important"}>
              {t("i-deletewarning")}.
            </AttentionBox>

            <h2 id={'tariffs'} className={"mb-40 mb-24-mob"}>{t("tarifbenefit")}</h2>

            <div className={'skip-search'}><TariffsTable/></div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default GuideCustomer;
