import {useSelector} from "react-redux";
import {getUserCurrentCity} from "@/stores/slices/userSlice";
import {getTitle} from "@/stores/slices/titleSlice";
import useDefiningCity from "@/hooks/useDefiningCity";
import useDefiningLang from "@/hooks/useDefiningLang";
import useNavigateToMain from "@/hooks/useNavigateToMain";
import Layout from "./Layout";
import useLadyService from "@/services/LadyService";
import {useEffect} from "react";
import {getUserRegion} from "@/stores/slices/userSlice";
import {setGmapsApiReady} from "@/stores/slices/mapModelSlice";
import useLanguageRedirect from "@/hooks/useLanguageRedirect";
import LayoutLk from "./LayoutLk";
import {useLocation} from "react-router-dom";
import {getCountrySlug} from "../../../stores/slices/userSlice";
import PrivateRoute from "../../private-route/private-route";

const MainLayout = ({initialData, children}) => {
    const {
        dispatch,
        userCity,
        t,
        userTypeAgency,
    } = useLadyService();
    const {pathname} = useLocation();
    useDefiningLang();
    useDefiningCity();
    useNavigateToMain();
    useLanguageRedirect();

    const currentCity = useSelector(getUserCurrentCity);
    const region = useSelector(getUserRegion);
    const countrySlug = useSelector(getCountrySlug)
    let postDesc = t("description", {City: currentCity?.title, Country: region})
    let postCity = t("incity", {City: currentCity?.title ?? ""})
    let postTitle = t("title", {City: currentCity?.title ?? "", CountryCode: 'cz'}) + " " + postCity;
    let h1Title = t("h1main")
    if (countrySlug) {
        postTitle = t("titlecountry", {Country: currentCity.title, CountryCode: 'cz'})
        postDesc = t("descriptioncountry", {Country: currentCity.title})
        postCity = `- ${currentCity?.title}`
        h1Title = t("h1country")
    }
    const title = useSelector(getTitle);
    const titleString = !!title.length
        ? title
            .filter((item) => !!item)
            .map((item) => t(item))
            .join(", ")
        : "";

    const titleParams = titleString
        ? `${userCity.title} - ${t("escortTitle")}: ${titleString}`
        : postTitle;

    const mapLink = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA81vyhvrBl3jUTFDK6LwYNm317prcxfVo&libraries=places&language=en`;
    const loadScript = (retryCount = 0) => {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.src = mapLink;
            script.async = true;

            script.onload = () => {
                console.log("%cGoogle Maps API loaded successfully. ", "color: green; font-size: 8px");
                dispatch(setGmapsApiReady(true));
                resolve();
            };

            script.onerror = () => {
                console.error("%cGoogle Maps API failed to load.", "color: red; font-size: 8px");
                if (retryCount < 5) {
                    console.log(`%cRetrying to load Google Maps API... Attempt: ${retryCount + 1}`, "color: orange; font-size: 8px");
                    setTimeout(() => {
                        loadScript(retryCount + 1).then(resolve).catch(reject);
                    }, 1000);
                } else {
                    reject(new Error("Google Maps API failed to load after multiple attempts."));
                }
            };

            document.body.appendChild(script);
        });
    };

    useEffect(() => {
        loadScript()
            .then(() => {
                console.log("%cGoogle Maps API loaded successfully. ", "color: green; font-size: 8px");
                dispatch(setGmapsApiReady(true));
            })
            .catch((error) => {
                console.error(`%cError loading Google Maps API: ${error}`, "color: red; font-size: 8px");
            });
    }, []);
    if (!pathname.includes("/lk/")) {
        return (
            <Layout
                titleParams={titleParams}
                initialData={initialData}
                postDesc={titleString ? `${h1Title}: ${titleString} - ${postCity}` : postDesc}
            >
                {children}
            </Layout>
        )
    } else {
        return (
            <PrivateRoute>
                <LayoutLk
                    titleParams={userTypeAgency ? titleParams : t("profuser") + " - Lady4Love"}
                    postDesc={postDesc}
                >
                    {children}
                </LayoutLk>
            </PrivateRoute>
        )
    }
};

export default MainLayout;
