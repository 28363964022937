import { Button, Icon } from "@/components/ui";
import React, { useEffect, useState } from "react";
import { setStoriesMap } from "@/stores/slices/popupSlice";
import { useParams } from "react-router-dom";
import { SwiperSlide } from "swiper/react";
import Reviews from "../../reviews/Reviews";
import GirlCard from "../../cards/girlCard/GirlCard";
import Slider from "../../sliders/Slider";
import ScheduleTable from "../../scheduleTable/ScheduleTable";
import StoriesComponent from "../../stories/StoriesComponent";
import StoriesComponentEdit from "@/components/stories/StoriesComponentEdit";
import { makeRequest } from "@/services/makeRequest";
import Gallery from "../../popups/gallery/Gallery";
import useLadyService from "@/services/LadyService";
import TabSliderModel from "../../sliders/tabSlider/tabSliderModel/TabSliderModel";
import { setOneModelPosition } from "@/stores/slices/mapModelSlice";
import ModelPornstarCard from "./components/pornstarCard/ModelPornstarCard";
import { Helmet } from "react-helmet";
import ModelInfoContent from "./components/infoContent/ModelInfoContent";
import ModelServices from "./components/serviceContent/ModelServices";
import {
  ErocontentAccess,
  LinkWithAgencyPopup,
  LinkWithGirlfriend,
  OrderModel,
  AddStoriesPopup
} from "../../popups";
import PrivateSlider from "../../sliders/privateSlider/PrivateSlider";
import ModelCounters from "./components/counters/ModelCounters";
import ModelButtonsGroup from "./components/buttonsGroup/ModelButtonsGroup";
import ModelHeader from "./components/header/ModelHeader";
import ModelExtras from "./components/extras/ModelExtras";
import { getOrderModelStatus } from "../../../stores/slices/popupSlice";
import { useSelector } from "react-redux";
import { Tabs } from "../../ui";
import MediaGlobal from "../accounts/createProfile/pageComponent/stepSection/media/MediaGlobal";
import TarifsGlobal from "../accounts/createProfile/pageComponent/stepSection/tarifs/TarifsGlobal";
import AppearanceGlobal from "../accounts/createProfile/pageComponent/stepSection/appearance/AppearanceGlobal";
import MainGlobal from "../accounts/createProfile/pageComponent/stepSection/main/MainGlobal";
import ServicesGlobal from "../accounts/createProfile/pageComponent/stepSection/services/ServicesGlobal";
import ZeroZone from "../../zeroZone/ZeroZone";
import EditBlock from "./components/editBlock/EditBlock";
import ModelAgencyCard from "./components/cards/ModelAgencyCard";
import ModelInteriorZone from "./components/cards/ModelInteriorZone";
import "../../sliders/newGrilsSlider/newGirlsSlider.scss";
import "./modelPage.scss";
import DeclineProfile from "../../popups/declineProfile/DeclineProfile";
import showToast from "../../toast/Toast";
import PartyCard from "../../cards/partyCard/PartyCard";
import { getTopProfilesData } from "../../../stores/slices/oneProfileSlice";
import useTitle from "../../../hooks/useTitle";
import { Loader } from "@/components/ui";
import { translateValue } from "../subscriptions/SubsItem/SubsItem";
import { getCurrentLinkCity, setLinksCurrentCity } from "@/stores/slices/userSlice";
import { extractKeysAndNumbers } from "@/stores/slices/filterSlice";
import { setDisabledFooterLinks } from "@/stores/slices/modelsState";

const ModelPage = ({ oneProfileData, props }) => {
  const [data, setData] = useState(oneProfileData);

  const { cities, languages, nationalities, services, setStories, edit, stories } =
    props;

  const { dispatch, lang, windowWidth, userType, t, navigate, setMediaSize, getTimeAgo } =
    useLadyService();

  const url = window.location.pathname;

  const isCheckProfileMode = url.includes("check-");

  const {
    id = data?.id,
    name = data?.name,
    slug = data?.slug,
    gender = data?.gender,
    orientation = data?.orientation,
    own = data?.own,
    description = data?.description,
    preferences = edit
      ? data?.description[lang]?.preferences
      : data?.preferences,
    note = data?.note,
    gf = data?.gf,
    age = data?.age,
    prices = data?.prices,
    rating = data?.rating,
    rating_charm = data?.rating_charm,
    rating_quality = data?.rating_quality,
    rating_communication = data?.rating_communication,
    rating_emotions = data?.rating_emotions,
    rating_atmosphere = data?.rating_atmosphere,
    reviews_count = data?.reviews_count,
    tag_booking = data?.tag_booking,
    ready_travel = data?.ready_travel,
    ready_videochat = data?.ready_videochat,
    is_top = data?.is_top,
    is_favorite = data?.is_favorite,
    last_activity = data?.last_activity,
    is_elite = data?.is_elite,
    is_escort = data?.is_escort,
    phone = data?.phone,
    telegram = data?.telegram,
    whatsapp = data?.whatsapp,
    city = data?.city,
    city_id = edit ? data?.city_id : city?.id,
    city_slug = edit ? "" : city?.slug,
    city_name = edit ? city : city?.name,
    address = data?.address,
    distance = data?.distance,
    media = data?.media,
    ero = data?.ero,
    ero_obj = {
      data: {
        description: edit
          ? ero?.data?.description[lang] &&
            ero?.data?.description[lang]?.description
          : ero?.data?.description,
        photo: edit
          ? ero?.data?.photo?.data?.map((i) => i.link)
          : ero?.data?.photo,
        photo_moderated: ero?.data?.photo?.data?.map((i) => i.moderated),
        photo_count: ero?.data?.photo_count,
        video: edit
          ? ero?.data?.video?.data?.map((i) => i.link)
          : ero?.data?.video,
        video_moderated: ero?.data?.video?.data?.map((i) => i.moderated),
        hide: ero?.data?.hide,
        price: ero?.data?.price,
        updated_at: ero?.data?.updated_at,
      },
    },
    porn_star = data?.porn_star,
    parties = data?.parties ?? [],
    agency = data?.agency,
    statistic = data?.statistic,
    reviews = data?.reviews,
    work_time = data?.work_time,
    discounts = data?.discounts,
    is_online = data?.is_online,
    new_prices = data?.new_prices
  } = data;

  const thisCity = useSelector(getCurrentLinkCity)

  useEffect(() =>{
    setStories(data?.stories);
    dispatch(setLinksCurrentCity(data?.city));
  }, [data])

  const orderModelValue = useSelector(getOrderModelStatus);

  const [isOpenEditPhoto, setIsOpenEditPhoto] = useState(false);
  const [isOpenEditAgency, setIsOpenEditAgency] = useState(false);
  const [isOpenEditGf, setIsOpenEditGf] = useState(false);
  const [isOpenEditAppearance, setIsOpenEditAppearance] = useState(false);
  const [isOpenEditMain, setIsOpenEditMain] = useState(false);
  const [isOpenEditServices, setIsOpenEditServices] = useState(false);
  const [isOpenEditTarifs, setIsOpenEditTarifs] = useState(false);

  const allowedValues = ["photo", "videofotograph", "interior", "erocontent"];

  const urlParams = window.location.hash.substring(1);

  const [visualElem, setVisualElem] = useState(
    allowedValues.includes(urlParams) ? urlParams : "photo"
  );

  const tabsData = edit
    ? [{ title: t("params") }, { title: t("services") }]
    : [
        { title: t("appearance") },
        { title: t("services") },
        { title: t("reviews") },
      ];

  const { profileSlug } = useParams();
  const topProfiles = useSelector(getTopProfilesData);
  const [sliderData, setSliderData] = useState(topProfiles);
  const [isSliderLoading, setIsSliderLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsSliderLoading(true);
      try {
        const route = `profiles`;
        const method = "GET";
        const payload = {
          per_page: 8,
          sort: "date",
          city: city_id,
          exclude: profileSlug,
        };

        const data = await makeRequest({ route, method, payload });
        if (data) {
          setSliderData(data.profiles);
          setIsSliderLoading(false);
          const res = extractKeysAndNumbers(data.filters)
          dispatch(setDisabledFooterLinks(res))
        }
      } catch (error) {
        console.log('error: ', error);
      }
    };

    !edit && lang && fetchData();
  }, [lang]);

  const toggleMapOpen = (slug) => {
    dispatch(setOneModelPosition(slug));
    dispatch(setStoriesMap(true));
  };
  
  useEffect(()=>{
    if(address){
      console.log('set', slug)
      dispatch(setOneModelPosition(slug));
    }
  }, [address])

  const [activeTab, setActiveTab] = useState(0);

  const postCity = t("incity", { City: city.name ?? "" });

  const fetchHandleProfile = async (type) => {
    try {
      const route = `profiles/${type}/${profileSlug}`;
      const method = "GET";

      const { result, slug } = await makeRequest({ route, method });

      if (result) {
        navigate(`/${lang}/lk/profile/${slug}/edit`);
        if (type === "decline") {
          return true;
        }
      }
    } catch (error) {
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const handleConfirm = () => {
    if (userType === "default") {
      localStorage.setItem("uui", profileSlug);
      navigate(`/${lang}/registration#indi`);
    } else {
      fetchHandleProfile("accept");
    }
  };

  const [declineProfileOpen, setDeclineProfileOpen] = useState(false);

  const handleDecline = () => {
    setDeclineProfileOpen(true);
  };
  useEffect(() => {
    dispatch(setStoriesMap(false));
  }, []);

  const isJsdom =
    typeof window !== "undefined" &&
    window.navigator.userAgent.includes("jsdom");

  const pageTitle = t("addTitle", {
    Name: name,
    City: city_name,
    Gender: t(translateValue?.[gender]),
    Orientation: t(translateValue?.[orientation]),
  });

  useTitle(pageTitle);
  const pageDescription = t("addDesc", {
    Name: name,
    description:
      typeof description === "object"
        ? description[lang]
          ? description[lang].description.slice(0, 150)
          : ""
        : description?.slice(0, 150),
  });

  const topSliderBreakpoints = {
    0: {
      slidesPerView: 2,
    },
    767.98: {
      slidesPerView: 4,
    },
    1000.98: {
      slidesPerView: 5,
    },
    1199.98: {
      slidesPerView: 6,
    },
    1399.98: {
      slidesPerView: 8,
  },
  }

  

  return (
    <main>
      {!!edit ? null : (
        <Helmet>
          <meta name="description" content={pageDescription}/>
          {/* <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5, user-scalable=1" /> */}
        </Helmet>
      )}

      <div className={isJsdom ? "model__container model ssr" : 'model__container model'}>
        
        <ModelHeader
          toggleMapOpen={toggleMapOpen}
          setIsOpenEditMain={setIsOpenEditMain}
          edit={edit}
          props={{
            oneProfileData,
            name,
            note,
            age,
            rating,
            reviews_count,
            tag_booking,
            ready_videochat,
            is_top,
            is_favorite,
            last_activity,
            is_elite,
            phone,
            telegram,
            whatsapp,
            city_name,
            city_slug,
            address,
            distance,
            stories,
            profileSlug,
            setStories,
            is_online
          }}
        />

        <section className={`model-main${edit ? " _edit" : ""} ${isJsdom ? 'ssr' : ''}`}>
          <div
            className={isJsdom ? `all-photo-container` : `model-main__sliders`}
          >
            {!!media?.photo?.length ||
            !!media?.interior?.length ||
            !!media?.video?.length ||
            !!ero_obj?.data?.video?.length ||
            !!ero_obj?.data?.photo?.length ? (
              !isJsdom ? (
                <TabSliderModel
                  name={name}
                  ero={ero_obj}
                  media={media}
                  clazz={"model-main"}
                  setIsOpenEdit={setIsOpenEditPhoto}
                  setVisualElem={setVisualElem}
                  own={own}
                  edit={!!edit}
                />
              ) : (
                <>
                  {!!media?.photo?.length &&
                    media.photo.map((media, index) => (
                        <div className={'photo-wrapper'} key={index}>
                          <img
                            src={setMediaSize(media, "s2")}
                            alt={t("altphotoprofile", { GirlName: name })}
                          />
                        </div>
                    ))}
                </>
              )
            ) : (
              <ZeroZone
                clazz={"model-main__slider"}
                mainText={t("upload1")}
                longDisc={t("openface")}
                setOpen={setIsOpenEditPhoto}
                visualElem={"photo"}
                setVisualElem={setVisualElem}
                setOpenEdit={setIsOpenEditPhoto}
              />
            )}

            {!isJsdom &&
            (!!ero_obj?.data?.video?.length ||
              !!ero_obj?.data?.photo?.length ||
              edit) ? (
              <PrivateSlider
                ero={ero_obj}
                own={own}
                edit={edit}
                setOpenEdit={setIsOpenEditPhoto}
                setVisualElem={setVisualElem}
              />
            ) : null}
          </div>

          {!isJsdom && (!!edit ||
          !!agency?.length ||
          !!porn_star?.length ||
          !!parties?.length) ? (
              <>
                {windowWidth < 1000.98 && (!!porn_star?.length || !!edit) ? (
                    <ModelPornstarCard
                        porn_star={porn_star}
                        setIsOpenEditPorno={setIsOpenEditPhoto}
                        setVisualElem={setVisualElem}
                        edit={edit}
                    />
                ) : null}
              </>
          ) : null}

          <div className="model__info">
            <ModelInfoContent
              edit={edit}
              props={data}
              setOpen={setIsOpenEditAppearance}
            />
          </div>

          {windowWidth > 1000.98 && (!!porn_star?.length || !!edit) ? (
            <ModelPornstarCard
              porn_star={porn_star}
              setIsOpenEditPorno={setIsOpenEditPhoto}
              setVisualElem={setVisualElem}
              edit={edit}
            />
          ) : null}

          {edit && !media?.interior?.length && (
            <ModelInteriorZone
              setVisualElem={setVisualElem}
              setIsOpenEditPhoto={setIsOpenEditPhoto}
            />
          )}
          
          {!isJsdom && (
            <div className="model-content tabs__content">
              <Tabs
                props={{
                  clazz: "mb-8",
                  tabsData,
                  activeTab,
                  setActiveTab,
                }}
              />

              <div
                className={`tabs__panel model__info${
                  activeTab === 0 ? " tabs__panel--active" : ""
                }`}
              >
                <ModelInfoContent
                  props={data}
                  setOpen={setIsOpenEditAppearance}
                  edit={edit}
                />
             
              </div>

              {isJsdom && <div>
                <ModelServices
                  props={data}
                  preferences={preferences}
                  setOpen={!!edit && setIsOpenEditServices}
                />
              </div>}

              {!isJsdom && <section
                className={`tabs__panel model__serfices${
                  activeTab === 1 ? " tabs__panel--active" : ""
                }`}
              >

                {edit && (
                  <EditBlock
                    title={t("services")}
                    onClick={() => setIsOpenEditServices(true)}
                    mb={12}
                    clazz={"align-center"}
                  />
                )}

                <ModelServices
                  props={data}
                  preferences={preferences}
                  setOpen={!!edit && setIsOpenEditServices}
                />
              </section>}

              <div
                className={`tabs__panel model__reviews${
                  activeTab === 2 ? " tabs__panel--active" : ""
                }`}
              >
                <Reviews
                  props={{
                    slug,
                    rating,
                    rating_atmosphere,
                    rating_charm,
                    rating_communication,
                    rating_emotions,
                    rating_quality,
                    reviews_count,
                    reviews,
                    name,
                  }}
                  id={id}
                  reviewable_type={"indi"}
                />
              </div>

              <div className="model-main__wrapper">
                {!!edit && (
                  <EditBlock
                    title={`${t("tariffs")}, ${t(
                      "workhours"
                    ).toLowerCase()} ${t("and")} ${t(
                      "discounts"
                    ).toLowerCase()}`}
                    onClick={() => setIsOpenEditTarifs(true)}
                    clazz={"mb-0 align-center"}
                  />
                )}

                {!!Object.values(prices)?.length || !!work_time?.length ? (
                  <ScheduleTable
                    props={{
                      workTime: work_time,
                      icon: "time-plan",
                      prices,
                      discounts,
                    }}
                  />
                ) : !!edit ? (
                  <ZeroZone
                    mainText={`${t("addad")} ${t("tariffs").toLowerCase()}, ${t(
                      "workhours"
                    ).toLowerCase()} ${t("and")} ${t(
                      "discounts"
                    ).toLowerCase()}`}
                    setOpen={setIsOpenEditTarifs}
                    disc={25}
                  />
                ) : null}

                {(!!distance || !!address) && (
                  <Button
                    size={"m"}
                    title={t("viewonmap")}
                    onClick={() => toggleMapOpen(profileSlug)}
                    clazz={"button--tetriary w-100 p2 gap-12"}
                  >
                    {!!distance && (
                      <span className="model-header__distance">
                        <Icon spritePath={"location"} size={"s"} />
                        {distance} {t("kmsfromyou")}
                      </span>
                    )}

                    {!!address && (
                      <span className="model-header__address p3">
                        <Icon spritePath={"map"} size={"s"} />
                        {city.name ? `${city.name}, ` : ""}
                        {address}
                      </span>
                    )}
                  </Button>
                )}

                <ModelButtonsGroup
                  size={windowWidth > 479.98 ? "l" : "m"}
                  props={{
                    oneProfileData,
                    ready_videochat,
                    phone,
                    tag_booking,
                    whatsapp,
                    profileSlug,
                    telegram,
                    own,
                  }}
                />

                <ModelExtras
                  props={{
                    gf,
                    prices,
                    ready_travel,
                    is_escort,
                    porn_star,
                  }}
                  setIsOpenEditMain={setIsOpenEditMain}
                  edit={edit}
                />
              </div>
            </div>
          )}

          {isJsdom && (
            <>
              <ModelServices
                props={data}
                preferences={preferences}
                setOpen={!!edit && setIsOpenEditServices}
              />

              <Reviews
                props={{
                  slug,
                  rating,
                  rating_atmosphere,
                  rating_charm,
                  rating_communication,
                  rating_emotions,
                  rating_quality,
                  reviews_count,
                  reviews,
                  name,
                }}
                id={id}
                reviewable_type={"indi"}
              />

              {!!edit && (
                <EditBlock
                  title={`${t("tariffs")}, ${t("workhours").toLowerCase()} ${t(
                    "and"
                  )} ${t("discounts").toLowerCase()}`}
                  onClick={() => setIsOpenEditTarifs(true)}
                  clazz={"mb-0 align-center"}
                />
              )}

              {!!Object.values(prices)?.length || !!work_time?.length ? (
                <ScheduleTable
                  props={{
                    workTime: work_time,
                    icon: "time-plan",
                    prices,
                    discounts,
                  }}
                />
              ) : !!edit ? (
                <ZeroZone
                  mainText={`${t("addad")} ${t("tariffs").toLowerCase()}, ${t(
                    "workhours"
                  ).toLowerCase()} ${t("and")} ${t("discounts").toLowerCase()}`}
                  setOpen={setIsOpenEditTarifs}
                  disc={25}
                />
              ) : null}

              {(!!distance || !!address) && (
                <Button
                  size={"m"}
                  title={t("viewonmap")}
                  onClick={() => toggleMapOpen(profileSlug)}
                  clazz={"button--tetriary w-100 p2 gap-12"}
                >
                  {!!distance && (
                    <span className="model-header__distance">
                      <Icon spritePath={"location"} size={"s"} />
                      {distance} {t("kmsfromyou")}
                    </span>
                  )}

                  {!!address && (
                    <span className="model-header__address p3">
                      <Icon spritePath={"map"} size={"s"} />
                      {city.name ? `${city.name}, ` : ""}
                      {address}
                    </span>
                  )}
                </Button>
              )}

              <ModelButtonsGroup
                size={windowWidth > 479.98 ? "l" : "m"}
                props={{
                  oneProfileData,
                  ready_videochat,
                  phone,
                  tag_booking,
                  whatsapp,
                  profileSlug,
                  telegram,
                  own,
                }}
              />

              <ModelExtras
                props={{
                  gf,
                  prices,
                  ready_travel,
                  is_escort,
                  porn_star,
                }}
                setIsOpenEditMain={setIsOpenEditMain}
                edit={edit}
              />
            </>
          )}
        </section>

        <ModelCounters statistic={statistic} />
      </div>

      <section className={"container mb-32"}>
      {!isJsdom && ((!!gf?.length || !!agency?.length || !!parties.length) || edit) ? (
            <div className={"model-card w-100"}>
              

              <div className="grid-cards"> 
                  {!!gf?.length ? (
                    gf?.map((data, index) => {
                      return (<>{data.status !== 0  ? 
                      <div key={index}>
                        {(edit) ? (
                          <EditBlock
                            title={t("girlsprofiles")}
                            onClick={() => setIsOpenEditGf(true)}
                            clazz={"align-center justify-sb"}
                            titleType={"h3"}
                          />
                        ) : (!!gf?.length &&
                          <p className={'title_h2'}>{t("girlsprofiles")}</p>
                        )}
                        <GirlCard props={data} mobile={true} key={index} />
                      </div> : null}</>);
                    })
                  ) : edit ? (
                  <ZeroZone
                    mainText={t("addgf") + " Lady4Love"}
                    setOpen={setIsOpenEditGf}
                    disc={5}
                  />
                ) : null}
                {!!agency?.length || edit ? (
                    <ModelAgencyCard
                        props={{
                          edit,
                          setIsOpenEditAgency,
                          agency,
                        }}
                    />
                ) : null}

                {!!parties.length ? (
                    <div className={"model-card model-card__parties"}>
                      <p className={'title_h2'}>{t("nextparty")}</p>
                      <div className={"model-card__wrapper"}>
                        {parties.map((data, index) => {
                          return <PartyCard party={data} key={index} />;
                        })}
                      </div>
                    </div>
                ) : null}
              </div>
            </div>
          ) : null}

      </section>

      {!!edit || isCheckProfileMode ? null : (
        <section className={"new-girls__container"}>
          <h2>
            {t("topmodels")} {postCity}
          </h2>
          {isSliderLoading && <Loader height={100} />}
          {!isJsdom && !isSliderLoading ? (
            <Slider clazz={"new-girls"} isAutoplay={windowWidth < 768} breakpoints={topSliderBreakpoints}>
              {sliderData.map((data) => {
                return (
                  <SwiperSlide key={data.id}>
                    <GirlCard big props={data} topModelsSlider={true}/>
                  </SwiperSlide>
                );
              })}
            </Slider>
          ) : (
            <div className={'top-models-ssr'}>
              {sliderData.map((data, index) => {
                return <GirlCard clazz={"w-360 min-w-auto"} props={data} key={index}/>;
              })}
            </div>
          )}
        </section>
      )}

      {!!ero?.data || !ero?.data?.hide ? (
        userType === "default" ? null : (
          <ErocontentAccess profileSlug={profileSlug} ero={ero_obj} />
        )
      ) : null}

      {!!tag_booking &&
      !(userType === "indi" || userType === "agency") &&
      !!orderModelValue ? (
        <OrderModel
          prices={prices}
          address={address}
          name={name}
          slug={profileSlug}
          props={ data }
        />
      ) : null}

      {!!stories?.length ? 
        (edit ? 
            <StoriesComponentEdit
                setStories={setStories}
                status={true}
                data={stories}
            />
        : 
          <StoriesComponent
            descriptionText
            storiesForOneModel={true}
            stories={stories}
          />
        ) : null}

      {!!ero_obj?.data?.photo?.length ||
      !!ero_obj?.data?.video?.length ||
      !!media?.photo?.length ||
      !!media?.interior?.length ||
      !!media?.video?.length ? (
        <Gallery name={name} media={media} ero={ero_obj} own={own} />
      ) : null}
      
      {!!edit ? (
        <>
          {isOpenEditPhoto && (
            <MediaGlobal
              open={isOpenEditPhoto}
              setOpen={setIsOpenEditPhoto}
              slug={profileSlug}
              setFormData={setData}
              formData={data}
              visualElem={visualElem}
              setVisualElem={setVisualElem}
            />
          )}

          {isOpenEditGf && (
            <LinkWithGirlfriend
              open={isOpenEditGf}
              setOpen={setIsOpenEditGf}
              slug={profileSlug}
              city_id={city_id}
            />
          )}

          {isOpenEditAgency && (
            <LinkWithAgencyPopup
              open={isOpenEditAgency}
              setOpen={setIsOpenEditAgency}
              slug={profileSlug}
            />
          )}

          {isOpenEditTarifs && (
            <TarifsGlobal
              setFormData={setData}
              open={isOpenEditTarifs}
              setOpen={setIsOpenEditTarifs}
              formData={data}
            />
          )}

          {isOpenEditAppearance && (
            <AppearanceGlobal
              setFormData={setData}
              open={isOpenEditAppearance}
              setOpen={setIsOpenEditAppearance}
              formData={data}
            />
          )}

          {isOpenEditMain && (
            <MainGlobal
              setFormData={setData}
              open={isOpenEditMain}
              setOpen={setIsOpenEditMain}
              formData={data}
              nationalities={nationalities}
              languages={languages}
              cities={cities}
            />
          )}

          {isOpenEditServices && (
            <ServicesGlobal
              setFormData={setData}
              open={isOpenEditServices}
              setOpen={setIsOpenEditServices}
              formData={data}
              services={services}
            />
          )}
        </>
      ) : null}

      {isCheckProfileMode && (
        <div className="videochat__bar checkprofile__bar">
          <div className="videochat__box">
            <Button
              clazz={"button--green _no-underline min-w-160"}
              size="l"
              onClick={() => handleConfirm()}
            >
              <span className={"display-none-mobile-small"}>
                {t("edit")} {t("and")}
              </span>{" "}
              {t("publish")}
            </Button>
            <Button
              clazz={"_no-underline button--secondary min-w-160"}
              size="l"
              onClick={() => handleDecline()}
            >
              {t("delete")}
            </Button>
          </div>
        </div>
      )}

      <DeclineProfile
        open={declineProfileOpen}
        setOpen={setDeclineProfileOpen}
        fetch={fetchHandleProfile}
      />

      <AddStoriesPopup
          slug={slug}
          setStories={setStories}
          stories={stories}
      />
    </main>
  );
};

export default ModelPage;
