import { Icon } from "../index";
import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getAllSorted,
  getNotDisabledCheckbox,
  getPage,
  setAllModels,
  setPageDefault,
  setSorted,
} from "@/stores/slices/modelsState";
import { Link } from "react-router-dom";
import {
  filterGender, filterServices,
  filterStatus,
  isFilterSearch,
  toggleState,
} from "@/stores/slices/filterSlice";
import { setTitle } from "@/stores/slices/titleSlice";
import { showShortFilter } from "@/stores/slices/showFilterSlice";
import { toggleSelected } from "../../../stores/slices/filterSlice";
import { getIsLoading } from "../../../stores/slices/modelsState";
import useLadyService from "../../../services/LadyService";

import "./checkbox.scss";
import { toggleStateCount } from "../../../stores/slices/counterFilterSlice";
import { hideShort } from "../../../stores/slices/showFilterSlice";

const Checkbox = ({ ...props }) => {
  const {
    id,
    name,
    title,
    translateKey,
    checked = false,
    disabled = true,
    value,
    clazz,
    border = true,
    flag,
    path,
    onChange,
    readOnly,
    isLink = false,
    filter_disabled = "",
    important,
    onClick = false,
    button = false
  } = props;

  const {
    dispatch,
    city,
    lang,
    windowWidth,
    isMainPage,
    isAgencyPage,
    navigate,
    scrollToTop
  } = useLadyService();

  let checkRef = useRef(null);
  const [isChecked, setIsChecked] = useState(checked);

  const { keysWithValueTrue } = useSelector(getNotDisabledCheckbox);
  const [isDisabled, setIsDisabled] = useState(disabled);
  const isModelsLoad = useSelector(getIsLoading);
  const shortFilterStatus = useSelector(showShortFilter);

  useEffect(() => {
    if (!isModelsLoad) {
      setIsDisabled(true);
    }
  }, [isModelsLoad]);

  useEffect(() => {
    setIsDisabled(true);
    if (keysWithValueTrue && keysWithValueTrue.length > 0) {
      const checkbox = keysWithValueTrue.find(
        (item) => item === filter_disabled
      );
      if (checkbox) {
        setIsDisabled(false);
      }
    }
  }, [keysWithValueTrue, city]);

  const sortedValue = useSelector(getAllSorted);
  const filterStatusValue = useSelector(filterStatus);
  const filterGenderValue = useSelector(filterGender);
  const filterServicesValue = useSelector(filterServices);
  const page = useSelector(getPage);
  const isFilterSelected = useSelector(isFilterSearch);

  const handleChange = (e) => {
    const value = e.target.value;
    const title = e.target.getAttribute("data-title");
    const path = e.target.getAttribute("data-path");
    
    setIsChecked((prev) => !prev);
    dispatch(toggleStateCount({ StateName: name, count: value }));
    if (page !== 1) {
      dispatch(setPageDefault());
    }
    dispatch(setAllModels([]));
    if (name === "status") {
      if (value !== "incall") {
        dispatch(toggleSelected({ value, name: "status" }));
      }
      dispatch(
        setSorted({
          stateName: "sorted",
          payload: value,
        })
      );
    }

    if (name === "gender") {
      dispatch(toggleSelected({ value, name: "status" }));
      dispatch(toggleSelected({ value, name: "gender" }));
      dispatch(
        setSorted({
          stateName: "sorted",
          payload: path,
        })
      );
    }
    if (name === "status") {
      dispatch(toggleState({ value, StateName: "status" }));
    }
    if (name === "gender") {
      dispatch(toggleState({ value, StateName: "gender" }));
    }
    if (name === "services") {
      dispatch(toggleState({ value, StateName: "services" }));
      dispatch(toggleSelected({ value, name: "services" }));
      dispatch(
          setSorted({
            stateName: "sorted",
            payload: path,
          })
      );
    }
    if (name === "nation") {
      dispatch(toggleState({ value, StateName: "nation" }));
      dispatch(toggleSelected({ value, StateName: "nation" }));
      dispatch(
          setSorted({
            stateName: "sorted",
            payload: path,
          })
      );
    }
    if (shortFilterStatus) {
      dispatch(hideShort());
    }
    if (!isMainPage && !isAgencyPage && windowWidth < 1200) {
      navigate(`/${lang}/${city}/${path ?? value}`);
    }
    dispatch(setTitle(title));
    console.log('goto top')
    setTimeout(()=>{
      scrollToTop();
      // window.scroll(0,0)
    },500)
    
  };

  useEffect(() => {
    if (!isFilterSelected) {
      const idExistsInSelectedCheckboxes =
        sortedValue.includes(value) || sortedValue.includes(path);
      setIsChecked(idExistsInSelectedCheckboxes);
    }
    if (name === "status" && isFilterSelected) {
      const idExistsInSelectedCheckboxes = filterStatusValue.includes(value);
      setIsChecked(idExistsInSelectedCheckboxes);
    }
    if (name === "gender" && isFilterSelected) {
      const idExistsInSelectedCheckboxes = filterGenderValue.includes(value);
      setIsChecked(idExistsInSelectedCheckboxes);
    }

    if (name === 'services' && isFilterSelected){
      const idExistsInSelectedCheckboxes = filterServicesValue.includes(value);
      setIsChecked(idExistsInSelectedCheckboxes);
    }
  }, [sortedValue]);

  let defauldCheckboxProps = {
    type: "checkbox",
    tabIndex: 1,
    disabled: isDisabled ? true : null,
    id: id,
    name: name,
    onChange: onChange,
    checked: isChecked,
    value: value,
    readOnly: readOnly,
  };

  if (isLink) {
    return (
      <div className={`checkbox-button checkbox-link ${isChecked ? " checkbox-button_checked" : ""}
          ${important ? ` _important` : ""}${clazz ? ` ${clazz}` : ""}`}>
        <Link to={isLink} title="" onClick={onClick}>
          <label
            htmlFor={id}
            tabIndex={0}
          >
            <input {...defauldCheckboxProps} />

            {title}
          </label>
        </Link>
      </div>
    );
  } else {
    return (
      <div
        className={`checkbox-button${isChecked ? " checkbox-button_checked" : ""}${
          isDisabled ? " checkbox-button_disabled" : ""
        }${important ? ` _important` : ""}
        ${clazz ? ` ${clazz}` : ""}`}
              >
        <input
          {...defauldCheckboxProps}
          onChange={handleChange}
          data-title={translateKey}
          data-disabled={filter_disabled}
          data-path={path}
          tabIndex={isDisabled ? null : 0}
          onKeyUp={(e) => {
            if (e.key === "Enter" || e.key === "Space") {
              handleChange(e);
            }
          }}
        />
        <label htmlFor={id} ref={checkRef}>
          {flag ? (
            <Icon
              type={"flag"}
              clazz={"checkbox-flag"}
              spritePath={`${flag}`}
            />
          ) : null}

          {title}
        </label>
      </div>
    );
  }
};

export default Checkbox;
