/* eslint-disable default-case */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Helmet } from "react-helmet";
import { Button, Icon, Time } from "@/components/ui";
import { useSelector } from "react-redux";
import { useStopwatch, useTimer } from "react-timer-hook";
import { getUserID } from "@/stores/slices/userSlice";
import debounce from "lodash.debounce";
import {
  getVideoCallModalStatus,
  getVideoCallRoom,
  cancelVideoCall,
  getLadyForCall,
  setVideoCallRoom,
  getVideoCallStatus,
} from "@/stores/slices/videoCallSlice";
import "./videoChat.scss";
import { Controller, useForm } from "react-hook-form";
import { Rating, Star } from "@smastrom/react-rating";
import { Link, useNavigate, useParams } from "react-router-dom";
import useLadyService from "@/services/LadyService";
import callingSound from "@/assets/audio/calling.mp3";
import { t } from "i18next";
import {InputInLabel} from "../ui";

import { makeRequest } from "../../services/makeRequest";
import showToast from "../toast/Toast";
import {
  getVideoCallFreeTime,
  getVideoCallMaxTime,
} from "../../stores/slices/videoCallSlice";
import {Popup} from "../popups";

const Timer = (props) => {
  const {
    startCall,
    setNotAnswered,
    setCallError,
    setStopwatchSecondOffset,
    onClickClose,
  } = props;
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(startCall.getSeconds() + 120);
  const { seconds, minutes } = useTimer({
    autoStart: true,
    expiryTimestamp,
    onExpire: () => {
      setNotAnswered(true);
      setCallError(t("noanswer"));
      setStopwatchSecondOffset(new Date());
      onClickClose(false, "wait_end");
    },
  });

  const secondTime = seconds < 10 ? `0${seconds}` : `${seconds}`;
  const minuteTime = minutes < 10 ? `0${minutes}` : `${minutes}`;
  return (
    <>
      {minuteTime}:{secondTime}
    </>
  );
};

const Stopwatch = (props) => {
  const { stopwatchSecondOffset, startVideochat, zero } = props;

  const stopwatchOffset = new Date();
  const secondsOffset = Math.floor(
    (stopwatchOffset - stopwatchSecondOffset) / 1000
  );
  stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + secondsOffset);

  const { seconds, minutes } = useStopwatch({
    autoStart: startVideochat,
    offsetTimestamp: stopwatchOffset,
    onExpire: () => {},
  });

  const secondTime = seconds < 10 ? `0${seconds}` : `${seconds}`;
  const minuteTime = minutes < 10 ? `0${minutes}` : `${minutes}`;

  if (!zero) {
    return <>00:00</>;
  }
  return (
    <>
      {minuteTime}:{secondTime}
    </>
  );
};

const VideoButton = (props) => {
  const { disableCamera, videoEnabled } = props;
  return (
    <Button
      size={"l"}
      square={true}
      title={t("onoffcamera")}
      onClick={disableCamera}
      clazz={`button--secondary button-icon videochat__video-button${
        !videoEnabled ? " _active" : ""
      }`}
    >
      <Icon
        spritePath={`${!videoEnabled ? "novideo" : "video-fill"}`}
        size={"s"}
      />
    </Button>
  );
};

const VideoChat = () => {
  const { dispatch, userType } = useLadyService();

  const modalStatus = useSelector(getVideoCallModalStatus);
  const roomID = useSelector(getVideoCallRoom);

  const userID = useSelector(getUserID);
  const ladyForCall = useSelector(getLadyForCall);

  

  const maxTime = useSelector(getVideoCallMaxTime);
  const freeTime = useSelector(getVideoCallFreeTime);
  const navigate = useNavigate();
  const userStream = useRef();
  const refVideo = useRef();
  const peerRef = useRef();
  const webSocketRef = useRef();
  const [girl, setGirl] = useState(ladyForCall)
  const [partnerVideo, setPartnerVideo] = useState(null);
  const [startVideochat, setStartVideochat] = useState(false);
  const [startCall, setStartCall] = useState(false);
  const [callEnd, setCallEnd] = useState(false);
  const [callError, setCallError] = useState(false);
  const [callCloseError, setCallCloseError] = useState(false);
  const [notAnswered, setNotAnswered] = useState(false);
  const [guestVideoEnabled, setGuestVideoEnabled] = useState(true);
  const [guestBarEnabled, setGuestBarEnabled] = useState(false);

  const userPrice = useRef();

  const [stopwatchSecondOffset, setStopwatchSecondOffset] = useState(
    new Date()
  );

  const [videoEnabled, setVideoEnabled] = useState(true);
  const { room } = useParams();

  const reviewIdRef = useRef(null);
  const startRef = useRef(null);

  const [uploadReview, setUploadReview] = useState(false);
  const callStatus = useSelector(getVideoCallStatus)
  useEffect(() => {
    if (callStatus === 'canceled') {
      setNotAnswered(true);
      setCallError(t("noanswer"));
      setStopwatchSecondOffset(new Date());
      onClickClose(false, "wait_end");
    }
  }, [callStatus]);
  useEffect(() => {
    if (room !== undefined && room !== roomID) {
      dispatch(setVideoCallRoom({ room_id: room }));
    }
  }, [room]);

  const { control, reset } = useForm({
    mode: "onChange",
  });

  const {
    register,
    reset: res2,
    getValues,
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onSubmit",
  });

  const starsStyles = {
    itemShapes: Star,
  };

  const RatingComponent = (props) => {
    const { name, onChange: onSubmit, fast = true } = props;

    register(name, { validate: (clazz) => clazz > 0 });

    return (
      <Controller
        control={control}
        name={name}
        rules={{
          validate: (clazz) => clazz > 0,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Rating
            className={`rating-stars rating-stars--gold${
              uploadReview && !fast ? " event-none" : ""
            }`}
            value={value}
            isRequired
            disabled={uploadReview && !fast}
            spaceInside="none"
            onChange={(value) => {
              onChange(value);
              setValue(name, value);
              fast &&
                onSubmit({
                  score: value,
                  body: getValues("body"),
                });
            }}
            onBlur={onBlur}
            itemStyles={starsStyles}
          />
        )}
      />
    );
  };

  const handleOffer = async (offer) => {
    console.log("Received Offer, Creating Answer");
    peerRef.current = createPeer();

    await peerRef.current.setRemoteDescription(
      new RTCSessionDescription(offer)
    );

    userStream.current.getTracks().forEach((track) => {
      peerRef.current.addTrack(track, userStream.current);
    });

    const answer = await peerRef.current.createAnswer();
    await peerRef.current.setLocalDescription(answer);

    webSocketRef.current.send(
      JSON.stringify({ answer: peerRef.current.localDescription })
    );
  };

  const closeCamera = async () => {
    if (userStream.current) {
      userStream.current.getTracks().forEach((track) => {
        if (track.readyState === "live") {
          track.stop();
        }
      });
    }
  };

  const callUser = () => {
    console.log("Calling Other User");
    peerRef.current = createPeer();

    userStream.current.getTracks().forEach((track) => {
      peerRef.current.addTrack(track, userStream.current);
    });

    // Обновленный поток отправляется после изменения состояния видео
    webSocketRef.current.send(
      JSON.stringify({
        offer: peerRef.current.localDescription,
      })
    );
  };

  const handleNegotiationNeeded = () => {
    console.log("Creating Offer");
    peerRef.current
      .createOffer()
      .then((offer) => peerRef.current.setLocalDescription(offer))
      .then(() => {
        setTimeout(() => {
          console.log("Candidates processing not ended. Ending it...");
          webSocketRef.current.send(
            JSON.stringify({ offer: peerRef.current.localDescription })
          );
        }, 1000);
      })
      .catch((e) => console.log(e));
  };

  const handleIceCandidateEvent = (e) => {
    console.log("Found Ice Candidate");
    if (e.candidate) {
      webSocketRef.current.send(JSON.stringify({ iceCandidate: e.candidate }));
    }
  };

  const handleTrackEvent = (e) => {
    setPartnerVideo(e.streams[0]);
  };

  const createPeer = () => {
    console.log("Creating Peer Connection");
    const peer = new RTCPeerConnection({
      iceServers: [
        {
          urls: "stun:stun.relay.metered.ca:80",
        },
        {
          urls: "turn:standard.relay.metered.ca:80",
          username: "dd41ff40517c1c1a921b3a52",
          credential: "x+5Oogy2z2lno+ca",
        },
        {
          urls: "turn:standard.relay.metered.ca:80?transport=tcp",
          username: "dd41ff40517c1c1a921b3a52",
          credential: "x+5Oogy2z2lno+ca",
        },
        {
          urls: "turn:standard.relay.metered.ca:443",
          username: "dd41ff40517c1c1a921b3a52",
          credential: "x+5Oogy2z2lno+ca",
        },
        {
          urls: "turns:standard.relay.metered.ca:443?transport=tcp",
          username: "dd41ff40517c1c1a921b3a52",
          credential: "x+5Oogy2z2lno+ca",
        },
      ],
    });

    peer.onnegotiationneeded = handleNegotiationNeeded;
    peer.onicecandidate = handleIceCandidateEvent;
    peer.ontrack = (e) => {
      handleTrackEvent(e);
    };
    return peer;
  };

  async function onClickClose(closeModal = false, reason = "") {
    reset();
    res2();
    stopSound();
    await closeCamera();
    if (webSocketRef.current) {
      if (callCloseError) {
        reason = callCloseError;
      }
      webSocketRef.current.send(JSON.stringify({ close: true, reason }));
    }
    setPartnerVideo(null);
    peerRef.current = null;
    setStartVideochat(false);

    if (closeModal) {
      setVideoEnabled(true);
      await dispatch(cancelVideoCall());
      if (!guestBarEnabled) {
        if(userType !== 'customer' && userType !== 'default'){
          navigate(`/lk/videochats`);
        }
      }
    }
    setCallEnd(true);
  }

  const disableCamera = async () => {
    if (userStream.current) {
      await userStream.current.getVideoTracks().forEach((track) => {
        track.enabled = !videoEnabled;
      });
    }
    if (startCall) {
      webSocketRef.current.send(
        JSON.stringify({ type: "video_status", status: !videoEnabled })
      );
    }
    setVideoEnabled(!videoEnabled);
  };

  let isPlaying = false;

  const [audioPlayer, setAudioPlayer] = useState(null);

  const stopSound = () => {
    if (audioPlayer) {
      audioPlayer.pause();
      audioPlayer.currentTime = 0;
      setAudioPlayer(null);
      isPlaying = false;
    }
  };

  const playSound = () => {
    if (isPlaying) return;

    if (audioPlayer) {
      audioPlayer.play();
    } else {
      const audio = new Audio(callingSound);
      audio.loop = true;
      audio.play();
      setAudioPlayer(audio);
    }
    isPlaying = true;
  };

  useMemo(() => {
    if (uploadReview) {
      setUploadReview(true);
    }
  }, [uploadReview]);

  const onSubmit = (data) => {
    const fetchData = async () => {
      try {
        const route = `reviews`;
        const method = "PUT";
        const payload = {
          score: data?.score || 0,
          body: data?.body || null,
          reviewable_type: "videoCall",
          room_uuid: roomID,
        };

        const { message, review_id } = await makeRequest({
          route,
          method,
          payload,
        });

        if (message === "review_created" && review_id) {
          reviewIdRef.current = review_id;
          setUploadReview(true);
        }
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };

  const onSubmitDebounced = useCallback(
    debounce((value) => {
      onSubmit(value);
    }, 500),
    []
  );

  useEffect(() => {
    if (guestBarEnabled && !notAnswered) {
      playSound();
    }
  }, [guestBarEnabled, notAnswered]);

  useEffect(() => {
    if (startVideochat) {
      stopSound();
    }
  }, [startVideochat]);

  useEffect(() => {
    const getCallError = (message) => {
      switch (message) {
        case "user_close":
          if (guestBarEnabled) {
            return t("noanswer");
          } else {
            return t("endcall");
          }
        case "camera_access":
          setStopwatchSecondOffset(new Date());
          return t("noanswer");
        case "wait_end":
          setStopwatchSecondOffset(new Date());
          return t("noanswer");
        case "dont_access":
          return t("callnotlive");
      }
    };

    const getMedia = async () => {
      const openCamera = async () => {
        const allDevices = await navigator.mediaDevices.enumerateDevices();
        const cameras = allDevices.filter(
          (device) => device.kind === "videoinput"
        );
        if (cameras.length === 0) {
          console.error("No devices found");
          return null;
        }
        const constraints = {
          audio: true,
          video: videoEnabled ? { deviceId: cameras[0].deviceId } : false,
        };
        return navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            return stream;
          })
          .catch((error) => {
            console.error(t("mediaerror") + ":", error);
            return null;
          });
      };

      openCamera().then((stream) => {
        const token = localStorage.getItem("user");
        if (webSocketRef.current !== undefined) {
          console.log(webSocketRef.current);
          return;
        }
        webSocketRef.current = new WebSocket(
          `wss://${process.env.REACT_APP_CALL_URL}/join?roomID=${roomID}&token=${token}`
        );
        webSocketRef.current.onopen = () => {
          if (stream === null) {
            setCallError(t("declinecamera"));
            setCallCloseError("camera_access");
            setNotAnswered(true);
            return;
          }
          setStartCall(new Date());

          webSocketRef.current.send(JSON.stringify({ join: true }));
          userStream.current = stream;

          webSocketRef.current.addEventListener("message", async (e) => {
            const message = JSON.parse(e.data);

            console.log("message", message);

            if (message.type === "call_end") {
              userPrice.current = message.price;
            }

            if (message.join) {
              callUser();
            }

            if (message.offer) {
              console.log("Receiving offer");
              setStartVideochat(true);
              setStopwatchSecondOffset(new Date());
              handleOffer(message.offer);
              startRef.current = true;
            }

            if (message.answer) {
              setStartVideochat(true);
              startRef.current = true;
              setStopwatchSecondOffset(new Date());
              peerRef.current.setRemoteDescription(
                new RTCSessionDescription(message.answer)
              );
              webSocketRef.current.send(
                JSON.stringify({ type: "video_status", status: videoEnabled })
              );
            }

            if (message.iceCandidate) {
              console.log("Receiving and Adding ICE Candidate");
              try {
                await peerRef.current.addIceCandidate(message.iceCandidate);
              } catch (err) {
                console.log("Error Receiving ICE Candidate", err);
              }
            }

            if (message.type === "call_end" || message.type === "call_error") {
              if (message.type === "call_error") {
                setCallError(message.message);
              } else {
                setCallError(getCallError(message.reason));
              }
              onClickClose();
            }

            if (message.type === "video_status") {
              setGuestVideoEnabled(message.status);
            }

            if (message.is_guest) {
              setGuestBarEnabled(message.is_guest);
            }
          });
        };
      });
    };

    if (modalStatus) {
      setCallEnd(false);
      setNotAnswered(false);
      setStopwatchSecondOffset(new Date());
      setCallError(false);
      setStartVideochat(false);
      getMedia();
    }
  }, [modalStatus, roomID, userID]);

  useEffect(() => {
    if (webSocketRef.current !== undefined) {
      webSocketRef.current.addEventListener("message", async (e) => {
        const message = JSON.parse(e.data);
        console.log("message", message);
        if (message.answer) {
          console.log("video_status", videoEnabled);
          webSocketRef.current.send(
            JSON.stringify({ type: "video_status", status: videoEnabled })
          );
        }
      });
    }
  }, [videoEnabled]);

  useEffect(() => {
    if (partnerVideo !== null && partnerVideo !== undefined) {
      refVideo.current.srcObject = partnerVideo;
    }
  }, [partnerVideo]);

  const VideoChatBar = () => (
      <div className="videochat__bar">
        <div className="videochat__box">
          <div className="videochat__timer">
            <p className={"p1 w-mc color-main"}>{t("calltime")}</p>
            <span className={"title_h2 color-green"}>    
                  <Stopwatch
                    stopwatchSecondOffset={stopwatchSecondOffset}
                    startVideochat={startVideochat}
                    zero={startRef.current}
                  />
                </span>
          </div>
          {guestBarEnabled && !callEnd ? (
            <VideoButton
              disableCamera={disableCamera}
              videoEnabled={videoEnabled}
            />
          ) : null}
          <Button
            clazz={"button--secondary videochat__bar-close"}
            onClick={() => onClickClose(callEnd)}
          >
            {callEnd ? t("toclose") : t("complete")}
          </Button>
        </div>
      </div>
  )


  return (
    <Popup
      open={!!modalStatus}
      wrapperClazz="videochat"
      container={'none'}
      onClick={(e)=>{e.preventDefault(); e.stopPropagation()}}
    >

      <Button onClick={onClickClose} clazz="button__close">
        <Icon size="l" spritePath="close"/>
      </Button>
      {callEnd ? (
        <>
          <div
            onClick={(e) => e.stopPropagation()}
            className="videochat__preview"
          >
            <VideoChatImageLink
              girl={girl}
              notAnswered={notAnswered}
              callEnd={callEnd}
            />

            {guestBarEnabled ? (
              <>
                {callError ? (
                  <h2>{callError}</h2>
                ) : !!startRef.current ? (
                  <>
                    <h2>{t("endcall")}</h2>
                    <form
                      className="stars-box"
                      onClick={(e) => e.stopPropagation()}
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      {callEnd && (
                        <div className="videochat__timer">
                          <p className={"p1 w-mc color-main"}>{t("calltime")}</p>
                            <span className={"title_h2 color-green"}>
                              <Stopwatch
                                stopwatchSecondOffset={stopwatchSecondOffset}
                                startVideochat={startVideochat}
                                zero={startRef.current}
                              />
                           </span>
                        </div>
                      )}
                      <div className="d-flex fd-row justify-sb">
                        <span>{t("costvideochat")}</span>{" "}
                        <div className="m-0 d-flex gap-4 align-center fd-row">
                          <span>{userPrice.current}</span>
                          <Icon
                            title={t("EroCoins")}
                            clazz={"color-main"}
                            spritePath={"erocoin"}
                            size={"xs"}
                          />
                        </div>
                      </div>

                      <div className="d-flex fd-row justify-sb align-center">
                        <span className="text-left">{t("makereview")}</span>
                        <RatingComponent name={"score"} fast={false} />
                      </div>
                      <InputInLabel
                        register={{ ...register("body") }}
                        type={"textarea"}
                        id={"AddReviewText"}
                        clazz={uploadReview ? "event-none" : ""}
                        placeholder={t("textreview")}
                      />

                      <Button
                        disabled={uploadReview}
                        size={"l"}
                        buttonType={"submit"}
                        clazz={"button--green"}
                        onClick ={()=>onClickClose(true)}
                      >
                        {uploadReview ? t("sent") : t("send")}
                      </Button>

                      {callEnd && (
                        <Button
                          clazz={"button--secondary videochat__bar-close"}
                          onClick={() => onClickClose(callEnd)}
                        >
                          {t("toclose")}
                        </Button>
                      )}
                    </form>
                  </>
                ) : (
                  <h2>{t("endcall")}</h2>
                )}
              </>
            ) : (
              <>
                {callError ? (
                  <h2>{callError}</h2>
                ) : (
                  <>
                    <h2>{t("endcall")}</h2>
                    <form
                      className="lady-end-call-bar"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="d-flex fd-row justify-sb">
                        <span>{t("yougetmoney")}</span>
                        <div className="m-0 d-flex gap-4 align-center fd-row">
                          <span>{userPrice.current}</span>
                          <Icon
                            title={t("EroCoins")}
                            clazz={"color-main"}
                            spritePath={"erocoin"}
                            size={"xs"}
                          />
                        </div>
                      </div>

                      <div className="d-flex fd-row justify-sb align-center">
                        <span className="text-left">{t("makereview")}</span>
                        <RatingComponent
                          name={"score"}
                          onChange={onSubmitDebounced}
                        />
                      </div>

                      <Button
                        clazz={"button--secondary videochat__bar-close"}
                        onClick={() => onClickClose(callEnd)}
                      >
                        {t("toclose")}
                      </Button>
                    </form>
                  </>
                )}
              </>
            )}
          </div>

          {!callEnd && <VideoChatBar/>}
        </>
      ) : (
        <>
          {startVideochat ? (
            <>
              <video playsInline autoPlay ref={refVideo}></video>
              {!guestVideoEnabled ? (
                <>
                  <div className={"videoDisabled"}>
                    <Icon spritePath={"novideo"} size="6xl" clazz={"_red"} />
                    <p>{t("clientcameraoff")}</p>
                  </div>
                </>
              ) : null}

              <VideoChatBar/>
            </>
          ) : (
            <>
              {guestBarEnabled ? (
                <>
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="videochat__preview"
                  >

                    <VideoChatImageLink
                      girl={girl}
                      notAnswered={notAnswered}
                      callEnd={callEnd}
                    />

                    {notAnswered ? (
                      <h2>{callError}</h2>
                    ) : (
                      <>
                        <h2 className="videochat__dotted">{t("calling")}</h2>
                        <div className="videochat__timer">
                          <div className="popup-form__inner gap-0">
                              <p className={"p1"}>{t("maxlimit")} <span className="color-main"><Time seconds={maxTime}/></span></p>
                              <p className={"p1 color-green"}>{t("freewithtariff")} <span className={"color-green"}><Time seconds={freeTime}/></span></p>
                          </div>
                          <p className="p1">{t("waitingfor")}</p>
                          <span className="title_h2 color-green">
                            <Timer
                              startCall={startCall}
                              setNotAnswered={setNotAnswered}
                              setCallError={setCallError}
                              setStopwatchSecondOffset={
                                setStopwatchSecondOffset
                              }
                              onClickClose={onClickClose}
                            />
                          </span>
                        </div>
                      </>
                    )}
                  </div>

                  <span className="d-flex gap-8">
                    {!notAnswered && (
                        <VideoButton
                          disableCamera={disableCamera}
                          videoEnabled={videoEnabled}
                        />
                      )}
                        <Button
                          clazz="button--secondary"
                          onClick={() => {
                            stopSound();
                            onClickClose();
                          }}
                        >
                          {notAnswered ? t("toclose") : t("cancel")}
                        </Button>
                  </span>
                </>
              ) : (
                <>
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="videochat__preview"
                  >
                    {callError ? (
                      <h2>{callError}</h2>
                    ) : (
                      <h2 className="videochat__dotted">{t("calllink")}</h2>
                    )}
                  </div>
                  <span className="d-flex gap-8">
                    <Button
                      clazz="button--secondary"
                      onClick={onClickClose}
                    >
                      {t("cancel")}
                    </Button>
                  </span>
                </>
              )}
            </>
          )}
        </>
      )}
    </Popup>
  );
};
// TODO: Добавить пропсы и в целом допилить
// const StarsBox = () => {
//   return (
//     <form
//       className="stars-box"
//       onClick={(e) => e.stopPropagation()}
//       onSubmit={handleSubmit(onSubmit)}
//     >
//       {callEnd && (
//         <div className="videochat__timer">
//           <p className={"p1 w-mc color-main"}>{t("calltime")}</p>
//           <span className={"title_h2 color-green"}>
//             <Stopwatch
//               stopwatchSecondOffset={stopwatchSecondOffset}
//               startVideochat={startVideochat}
//               zero={startRef.current}
//             />
//          </span>
//         </div>
//       )}
//       <div className="d-flex fd-row justify-sb">
//         <span>{t("costvideochat")}</span>{" "}
//         <div className="m-0 d-flex gap-4 align-center fd-row">
//           <span>{userPrice.current}</span>
//           <Icon
//             title={t("EroCoins")}
//             clazz={"color-main"}
//             spritePath={"erocoin"}
//             size={"xs"}
//           />
//         </div>
//       </div>
//
//       <div className="d-flex fd-row justify-sb align-center">
//         <span className="text-left">{t("makereview")}</span>
//         <RatingComponent name={"score"} fast={false}/>
//       </div>
//       <InputInLabel
//         register={{...register("body")}}
//         type={"textarea"}
//         id={"AddReviewText"}
//         clazz={uploadReview ? "event-none" : ""}
//         placeholder={t("textreview")}
//       />
//
//       <Button
//         disabled={uploadReview}
//         size={"l"}
//         buttonType={"submit"}
//         clazz={"button--green"}
//       >
//         {uploadReview ? t("sent") : t("send")}
//       </Button>
//
//       {callEnd && (
//         <Button
//           size={"l"}
//           clazz={"button--secondary videochat__bar-close"}
//           onClick={() => onClickClose(callEnd)}
//         >
//           {t("toclose")}
//         </Button>
//       )}
//     </form>
//   )
// }

let VideoChatImageLink = ({girl, notAnswered, callEnd}) => {
  const { setMediaSize } = useLadyService();

  useEffect(()=>{
    console.log('event: ', girl);
  }, [girl])
    
  return(
    <Link
      to={ girl?.profile?.slug ?`/profile/${girl?.profile?.slug}` : '/'}
      className="popup-form__inner color-green text-center"
      target={"_blank"}
    >
      {girl?.profile?.photo[0] ? <img
        className="videochat__image"
        src={setMediaSize(girl?.profile?.photo[0], "s")}
        alt=""
      /> : null}
      {girl?.profile?.name}
      {notAnswered || callEnd ? null : (
        <audio autoPlay loop />
      )}
    </Link>
  )
}

export default VideoChat;
