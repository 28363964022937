import useLadyService from "@/services/LadyService";
import { InputInLabel, Icon } from "@/components/ui";
import { useEffect, useMemo, useState } from "react";
import { Select } from "@/components/ui";
import { calculateWithCommission } from "@/helper/calculateWithCommission";
import { setUserInfo } from "@/stores/slices/userSlice";

export const InputForm = ({
  commission,
  commissionText,
  creditType,
  commissionRate,
  currency,
  setCurrency,
  type,
  valueCoin,
  setValueCoin,
  setValuePrice,
  valuePrice,
}) => {
  const windowWidth = window.innerWidth;
  const isMobile = useMemo(() => windowWidth < 767.98, [windowWidth]);

  const { t, dispatch } = useLadyService();
  const currencyRates = {
    czk: 25,
    eur: 1,
  };

  const currencyOption = {
    main_title: t("currency"),
    options: [
      { id: 1, title: "EUR", value: "eur" },
      { id: 2, title: "CZK", value: "czk" },
    ],
  };

  const calculateAmmount = (val) => {
    setValueCoin(val);
    const value = parseFloat(val) || 0;
    const rate = currencyRates[currency] || 1;
    const adjustedValue = value * rate;
    const convertedValue = calculateWithCommission(
      adjustedValue,
      commissionRate
    );
    const roundedValue = parseFloat(convertedValue.toFixed(2));
    setValuePrice(roundedValue);
    dispatch(setUserInfo({ stateName: "amount", value: adjustedValue }));
  };

  const newValue =
    valueCoin * currencyRates[currency] +
    valueCoin * currencyRates[currency] * commissionRate;

  useEffect(() => {
    if (currency) {
      setValuePrice(newValue);
      dispatch(
        setUserInfo({
          stateName: "amount",
          value: newValue,
        })
      );
    }
  }, [currency]);

  useEffect(() => {
    if (type) {
      setValuePrice(newValue);
    }
  }, [type]);

  console.log('valueCoin', valueCoin)

  return (
    <>
      <div className="d-flex align-center w-100 border-right gap-16">
        <fieldset className="mb-8">
          <legend><span>{isMobile ? t("summ") : t("topupamount")}</span></legend>
          <InputInLabel
            price={"erocoin"}
            id={"earn-to"}
            type={"number"}
            placeholder={valueCoin}
            onChange={(e) => calculateAmmount(e.target.value)}
            clazz={`${isMobile ? "" : "min-w-160"}`}
          >
          </InputInLabel>
        </fieldset>
        <fieldset className="mb-8">
          <legend>{t("currency")}</legend>
          <Select
            onChange={setCurrency}
            options={currencyOption.options}
            clazz={"button button-xs justify-start mt-10"}
            arrowSize={"s"}
            name={"currency"}
            defaultTitle={
              currencyOption.options.filter((item) => item.value === currency)[0]
                ?.title || "eu"
            }
            subTitle={t("currency")}
            
            withoutTitle={true}
            postApi={true}
          />
        </fieldset>
        <Icon size={"m"} spritePath={"arrow-right"}  clazz={''}/>
        <div className="balance-page your__balance gap-8 mr-8 mb-8">
          <fieldset>
            <legend>{t("topayamount")}</legend>
            <span
                className={`p1 color-main ml-4 ${
                currency === "eur" ? "currency--euro" : "currency--czk"
              }`}
            >
              {valuePrice}
            </span>
          </fieldset>
        </div>
      </div>
      <span className="topup-input__desc">
        {creditType}
        <br />
        <p className={"p2 color-green m-0"}>
          {!!commission
            ? commissionText + " - " + commission + " %"
            : commissionText}
        </p>
      </span>
    </>
  );
};
